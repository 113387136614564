export const TAGS = {
  ALC: 'A la Carte',
  ALC_NEW: 'A la Carte - NEW',
  BULK_DONATION: 'Bulk Donation',
  BULK_DTC: 'Bulk DTC',
  BULK_DTC_STORE: 'Bulk DTC Store',
  BULK_SAMPLES: 'Bulk Samples',
  CLUB_12: 'Club 12',
  CLUB_12_NEW: 'Club 12 - NEW',
  CLUB_24: 'Club 24',
  CLUB_24_NEW: 'Club 24 - NEW',
  CLUB_NO_RED: 'Club No Red',
  CLUB_RED_ONLY: 'Club Red Only',
  CLUB_CUSTOMIZED: 'Club Customized',
  CLUB_DEFAULT: 'Club Default',
  CLUB_ALC: 'Club A la Carte',
  CLUB_ANNIVERSARY_1: 'Club 1yr Anniversary',
  CLUB_ANNIVERSARY_1_BELATED: 'Club Belated 1yr Anniversary',
  DNS: 'DNS',
  EVENT: 'Event',
  FEDEX: 'FedEx',
  GIFT_CARDS_ONLY: 'Gift Cards Only',
  GIFT_MESSAGE: 'Gift Message',
  PO_BOX: 'PO Box',
  WHOLESALE: 'Wholesale',
}
