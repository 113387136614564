import React from 'react'
import ReduxProvider from './redux-provider'
import { Location } from '@reach/router'
import { QueryParamProvider } from 'use-query-params'
import { useAnalytics } from '@chordcommerce/react-autonomy'
import { useEffect } from 'react'

const PageTracker = ({ location, options = {} }) => {
  const { segmentConfig } = options
  const { chordAnalytics } = useAnalytics()

  useEffect(() => {
    const id = setTimeout(() => {
      chordAnalytics.page(document.title)
    }, segmentConfig.trackPageDelay || 50)

    return () => clearTimeout(id)
  }, [chordAnalytics, location, segmentConfig.trackPageDelay])

  return null
}

const WrapWithAllProviders = ({ element }, pluginOptions) => {
  return (
    <ReduxProvider options={pluginOptions}>
      <Location>
        {({ location }) => (
          <>
            <PageTracker location={location} options={pluginOptions} />
            <QueryParamProvider location={location}>
              {element}
            </QueryParamProvider>
          </>
        )}
      </Location>
    </ReduxProvider>
  )
}

export default WrapWithAllProviders
